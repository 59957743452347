import React from "react"
import WhitePaperForm from "../components/white-paper-form"
import Layout from "../components/layout"
import SEO from "../components/seo"

const WhitePaper = () => {
  return (
    <Layout>
      <SEO title="White Paper" keywords={[`gatsby`, `application`, `react`]} />
      <WhitePaperForm />
    </Layout>
  )
}

export default WhitePaper
